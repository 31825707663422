import { Card } from '@packages/sk8/card'
import { Addon } from '@packages/types'
import { useFlags } from 'flagsmith/react'
import React from 'react'

import Switch from 'common/components/Switch'
import classMerge from 'utils/classMerge'

interface AddonsListProps {
  subscriptionAddons: Addon[]
  availableAddons: Addon[]
  onAdd: (addon: Addon) => void
  onRemove: (addon: Addon) => void
}

const AddonsList = ({ subscriptionAddons: subscribedAddons, availableAddons, onAdd, onRemove }: AddonsListProps) => {
  const flags = useFlags(['quote_addon'])

  return (
    <>
      {availableAddons.map(addon => {
        if (addon.addonId === 'configure_price_quote' && !flags.quote_addon.enabled) return null

        const isAdded = !!subscribedAddons.find(subAddon => subAddon.addonId === addon.addonId)
        return (
          <Card.Section key={addon.addonId} className={classMerge('md:flex-col border-t border-neutral-100')}>
            <div className="flex justify-between items-center">
              <div className="flex space-x-1 items-center">
                <Switch
                  className="mr-2"
                  aria-label="addon-switch"
                  id={addon.addonId}
                  onChange={e => {
                    if (e.target.checked) {
                      onAdd(addon)
                    } else {
                      onRemove(addon)
                    }
                  }}
                  checked={isAdded}
                />
                <label htmlFor={addon.addonId} className={classMerge('font-medium', { 'text-neutral-400': !isAdded })}>
                  {addon.name}
                </label>
              </div>
              <span className={classMerge('h-fit w-fit ml-2 text-primary-600', { 'text-neutral-300': !isAdded })}>
                +${addon.price} monthly
              </span>
            </div>
            <span
              className={classMerge('pt-4', {
                'text-neutral-300': !isAdded,
                'text-neutral-400': isAdded,
              })}
            >
              {addon.description}
            </span>
            {addon.promotion && <span className="mt-2 text-tertiary-green-400">{addon.promotion.description}</span>}
          </Card.Section>
        )
      })}
    </>
  )
}

export default AddonsList
