import { Card } from '@packages/sk8/card'
import { DatePickerInput } from '@packages/sk8/input'
import { useFormikContext } from 'formik'
import React, { useContext } from 'react'

import QuoteContext from 'cms/quotes/QuoteContext'
import { formatDisplayDate } from 'utils/dateUtils'

import { QuoteFormValues } from './Quote'
import QuotesLoadingSkeleton from './QuoteLoadingSkeleton'

const QuoteExpirationCard = () => {
  const { quote, isLoadingQuotes, isEditing } = useContext(QuoteContext)
  const { values, errors, setFieldValue } = useFormikContext<QuoteFormValues>()

  return (
    <Card className="w-[263px] flex-col">
      <Card.Section className="md:flex-col">
        <span className="text-xs font-medium text-neutral-600 pb-2">Quote expiration</span>
        {!isLoadingQuotes && quote ? (
          <span>
            {isEditing ? (
              <DatePickerInput
                name="expireOn"
                id="expireOn"
                selected={values.expireOn ? new Date(values.expireOn) : undefined}
                onValueChange={val => setFieldValue('expireOn', val)}
                minDate={new Date()}
                hasError={errors.expireOn != null}
              />
            ) : quote.expireOn ? (
              formatDisplayDate(quote.expireOn)
            ) : (
              <QuotesLoadingSkeleton />
            )}
          </span>
        ) : (
          <QuotesLoadingSkeleton />
        )}
      </Card.Section>
    </Card>
  )
}

export default QuoteExpirationCard
