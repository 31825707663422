import { IconButton } from '@packages/sk8/button'
import { CheckboxButtonGroup } from '@packages/sk8/input'
import { CustomizerProductSubmitAction, AddonId } from '@packages/types'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import Panel from 'builder/build/common/components/panels/Panel'
import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import useCustomizerSelector from 'builder/build/customizer/hooks/useCustomizerSelector'
import { useDispatch, useSelector } from 'cms/hooks'
import QuoteAddonCard from 'cms/quotes/components/Quotes/QuoteAddonCard'
import useIsSubscribedToAddon from 'cms/subscription/hooks/useIsSubscribedToAddon'
import withFlag, { FeatureFlag } from 'common/users/components/withFlag'
import * as quoteActions from 'customizer/quote/actions'
import * as quoteSelectors from 'customizer/quote/selectors'
import EyeIcon from 'icons/core-solid/interface-essential/interface-edit-view.svg'

import { updateSubmitAction } from '../actions'
import { submitActionSelector } from '../selectors'

const SubmitActionRightPanel = () => {
  const customizerDispatch = useCustomizerDispatch()
  const isQuoteFormVisible = useCustomizerSelector(quoteSelectors.quoteFormVisibilitySelector)
  const submitAction = useSelector(submitActionSelector)
  const dispatch = useDispatch()
  const location = useLocation()

  const isSubscribedToAddon = useIsSubscribedToAddon({ addonId: AddonId.ConfigurePriceQuote })

  const handleSubmitActionClick = (newSubmitAction: CustomizerProductSubmitAction) => {
    if (isQuoteFormVisible) customizerDispatch(quoteActions.closeForm())
    dispatch(updateSubmitAction(newSubmitAction))
  }

  const handlePreviewModalClick = () => {
    customizerDispatch(isQuoteFormVisible ? quoteActions.closeForm() : quoteActions.openForm())
  }

  useEffect(() => {
    if (!location.pathname.includes('/submit-action') && isQuoteFormVisible) {
      customizerDispatch(quoteActions.closeForm())
    }
  }, [location.pathname])

  return (
    <Panel side="right">
      <div className="flex flex-col px-6 py-6 space-y-2">
        <span className="font-medium text-sm">Submit Action</span>

        <div className="flex justify-between shadow-xs rounded-[4px]">
          <CheckboxButtonGroup className="flex-1">
            <CheckboxButtonGroup.Option
              isChecked={submitAction === CustomizerProductSubmitAction.AddToCart}
              onClick={() => handleSubmitActionClick(CustomizerProductSubmitAction.AddToCart)}
            >
              Add to cart
            </CheckboxButtonGroup.Option>
            <CheckboxButtonGroup.Option
              isChecked={submitAction === CustomizerProductSubmitAction.GetAQuote}
              onClick={() => handleSubmitActionClick(CustomizerProductSubmitAction.GetAQuote)}
            >
              Get a quote
            </CheckboxButtonGroup.Option>
          </CheckboxButtonGroup>
        </div>
      </div>
      {submitAction === CustomizerProductSubmitAction.GetAQuote && (
        <>
          <hr className="border-neutral-100" />
          <FeatureFlag feature="quote_addon">
            <>
              {isSubscribedToAddon === false && (
                <div className="p-2">
                  <QuoteAddonCard variant="small" />
                </div>
              )}
            </>
          </FeatureFlag>
          <div className="flex flex-col px-6 py-6 space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium">Quote request form</span>
              <IconButton
                aria-label="Preview quote request form"
                variant="subtle"
                Icon={EyeIcon}
                onClick={handlePreviewModalClick}
              />
            </div>
            <span className="text-xs text-neutral-400">
              Display the form shown to the user when they request a quote.
            </span>
          </div>
        </>
      )}
    </Panel>
  )
}

export default withFlag({
  Component: SubmitActionRightPanel,
  feature: 'quote_phase_1',
})
