import { Price } from '@packages/sk8/price'
import { Table } from '@packages/sk8/table'
import { useFormikContext } from 'formik'
import React, { useContext } from 'react'

import QuoteContext from 'cms/quotes/QuoteContext'
import { selectQuoteTotalPrices } from 'cms/quotes/utils'

import { QuoteFormValues } from './Quote'

const QuoteTableFooter = () => {
  const { quote } = useContext(QuoteContext)
  const formik = useFormikContext<QuoteFormValues>()
  if (!quote) return null

  const totalPrice = selectQuoteTotalPrices(quote, formik)

  return (
    <Table.Footer data-testid="quote-table-footer">
      <Table.FooterRow className="border-b-0">
        <Table.FooterCell className="text-right py-3 pr-6 font-medium" colSpan={4}>
          <div className="flex flex-row justify-end align-center">
            <span className="pr-12">Total price</span>
            <Price
              amount={totalPrice}
              currency={quote.currency ?? 'USD'}
              className="font-medium text-sm pl-1 text-neutral-900"
            />
          </div>
        </Table.FooterCell>
      </Table.FooterRow>
    </Table.Footer>
  )
}

export default QuoteTableFooter
