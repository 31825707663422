import { useQuery } from '@tanstack/react-query'

import useAddonService from 'common/addon/hooks/useAddonService'

import useSubscriptionService from './useSubscriptionService'

const useIsSubscribedToAddon = () => {
  const subscriptionService = useSubscriptionService()
  const addonService = useAddonService()

  const { data: availableAddons } = useQuery(addonService.fetchAll.queryKeys, addonService.fetchAll)

  const { data: subscription } = useQuery(subscriptionService.fetchOne.queryKeys, subscriptionService.fetchOne)

  if (!subscription || !availableAddons) return null

  const subscribedAddons = subscription.addons ?? []
  const additionalSubscribedAddons = subscribedAddons.filter(
    addon => !availableAddons.find(availableAddon => availableAddon.addonId === addon.addonId)
  )

  const allAddons = [...availableAddons, ...additionalSubscribedAddons]

  return allAddons.map(addon => ({
    isSubscribed: subscription.addons.find(subscribedAddon => subscribedAddon.addonId === addon.addonId) != null,
    addon,
  }))
}

export default useIsSubscribedToAddon
