import countries from '@packages/data/countries.json'
import {
  BulkOrderCustomizationDesign,
  Quote,
  BulkOrderDesign,
  CustomizationDesign,
  Design,
  DesignType,
} from '@packages/types'
import { useFormikContext } from 'formik'
import { sum } from 'lodash'

import { BulkOrderFormItem, CustomizationFormItem, QuoteFormValues } from './components/Quote/Quote'

export const defaultPersistenceState = {
  stores: [],
  status: [],
  startDate: undefined,
  endDate: undefined,
  expirationStartDate: undefined,
  expirationEndDate: undefined,
  collectionSize: 0,
  resultSize: 0,
  lastIndex: 0,
  count: 25,
  filter: '',
  sortKey: undefined,
  sortOrder: undefined,
}

export const numberFormatter = (unformattedNumber: string | number | undefined) => {
  if (!unformattedNumber || isNaN(Number(unformattedNumber))) return 0
  return Number(unformattedNumber)
}

export const selectQuoteTotalItems = (design: Design) => {
  const total = design
    ? (design as BulkOrderDesign).designs?.reduce(
        (total: number, item: BulkOrderCustomizationDesign) => total + item.quantity,
        0
      )
    : null

  return total
}

export const selectCountryName = (countryCode?: string) => {
  return countries.find(country => country.countryShortCode === countryCode)?.countryName
}

export const selectQuoteTotalPrices = (quote: Quote, formik: ReturnType<typeof useFormikContext<QuoteFormValues>>) => {
  if (!formik.values?.items || !quote || !quote.items) return 0

  const getCustomizationDesignPrice = (design: CustomizationFormItem, quantity: number) => {
    const { basePrice = 0, pricing = {} } = design
    const extraPricing = Object.values(pricing).reduce<number>((acc, price) => acc + Number(price), 0)
    return Number(quantity) * (Number(basePrice) + extraPricing)
  }

  const getDesignExtraPrices = (design: Design) => {
    return (
      design.customExtraPrices?.reduce((acc, detail) => {
        if (!detail.price || !detail.quantity) return acc
        return acc + detail.price * detail.quantity
      }, 0) || 0
    )
  }

  const totalPrice = formik.values.items.reduce((acc, item) => {
    if (item.design.type === DesignType.CustomizationDesign) {
      return acc + getCustomizationDesignPrice(item.design, item.quantity) + getDesignExtraPrices(item.design as Design)
    }

    const subDesignsPrice = (item.design as BulkOrderDesign).designs.reduce((acc, design) => {
      return acc + getCustomizationDesignPrice(design.design, design.quantity)
    }, 0)

    return acc + getDesignExtraPrices(item.design as Design) + subDesignsPrice
  }, 0)

  if (!formik.values.applyTaxes) return totalPrice
  const taxes = Number(formik.values.taxes) || 0
  const quoteTaxes = (totalPrice * taxes) / 100
  return totalPrice + quoteTaxes
}

export const selectQuoteBulkPrice = (design: BulkOrderFormItem) => {
  const sortedBulkPrices = design.bulkPrices
    ? [...design.bulkPrices].sort((a, b) => Number(b.quantity) - Number(a.quantity))
    : []

  const totalQuantity = sum(design.designs.map(d => d.quantity))
  return sortedBulkPrices.find(bulkPrice => totalQuantity >= Number(bulkPrice.quantity)) || null
}

export const toFormValues = (quote?: Quote): QuoteFormValues => {
  if (!quote) {
    return {
      items: [],
      applyTaxes: false,
      expireOn: new Date(),
      taxes: 0,
      id: '',
    }
  }

  const formatCustomizationDesign = (design: CustomizationDesign) => {
    return {
      type: design.type,
      id: design.id,
      pricing: design.pricing,
      basePrice: design.basePrice,
      price: design.price,
      customExtraPrices: design.customExtraPrices,
    }
  }

  return {
    applyTaxes: quote.applyTaxes,
    expireOn: quote.expireOn,
    taxes: quote.taxes,
    id: quote.id,
    items: quote.items.map(item =>
      item.design.type === DesignType.CustomizationDesign
        ? {
            design: formatCustomizationDesign(item.design),
            quantity: item.quantity,
          }
        : {
            design: {
              type: item.design.type,
              id: item.design.id,
              ignoreBulkPrices: item.design.ignoreBulkPrices,
              bulkPrices: item.design.bulkPrices,
              customExtraPrices: item.design.customExtraPrices,
              designs: item.design.designs.map(bulkDesignItem => {
                return {
                  design: formatCustomizationDesign(bulkDesignItem.design),
                  quantity: bulkDesignItem.quantity,
                }
              }),
            },
            quantity: item.quantity,
          }
    ),
  }
}
